import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import { useAlternateLangs } from "../../components/Hreflangs";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";


const breadCrumbLevels = {
    Hjem: "/",
    "Om mig": "/da/om-mig",
    "SEO Freelancer": "/da/seo-freelancer"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
    // replace with pages x-default
    "/en/seo-freelancer"
);


const SeoFreelancer = props => {

    return (
        <Layout location={props.location} alternateLangs={alternateLangs}>
            <React.Fragment>
                <SEO
                    title="SEO Freelancer til leje: Profil af M. Kupperschmidt"
                    description="Jeg er en freelance SEO-konsulent med mange års erfaring fra bureauer, og du kan hyre mig til at bringe din hjemmeside til toppen af Googles rangeringer. Kontakt mig direkte."
                    lang="da"
                    image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                    alternateLangs={alternateLangs}
                    canonical="/da/seo-freelancer"
                />
                <MainContent article>
                    <Img
                        src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                        alt="Matthias Kupperschmidt taler om keyword difficulty ved SEO konsulentmøde i København, Danmark, oktober 2019"
                    />
                    <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                    <H as="h1">Hyre en freelance SEO-konsulent</H>
                    <ProfileCard
                        tags={["SEO Content", "Backlinks", "Technical SEO", "Onpage SEO", "HTML/CSS/JavaScript", "React", "Python", "EN, DE, DA"]}
                        profession="SEO Konsulent"
                        rate={`fra ${monthlySeo}€ månedligt`}
                        location="Berlin, Tyskland / fjernarbejde"
                        cta="Kontakt"
                    />
                    <p>Jeg er en SEO freelancer, og du kan hyre mig til at få din hjemmeside til toppen af Googles søgeresultater.</p>
                    <p>Jeg tilbyder samme service som et SEO bureau, bare som en uafhængig SEO-konsulent. Jeg overtager planlægning, udførelse og kodeimplementering eller samarbejder med dit webbureau.</p>
                    <p>Tidligere har jeg arbejdet i forskellige SEO bureauer for enterprise-klienter og ecommerce-virksomheder, hvor jeg dagligt beskæftigede mig med søgemaskineoptimering.</p>
                    <p>I 2018 tog jeg springet til at begynde at arbejde som freelance SEO-konsulent. Udover at optimere Googles søgerangeringer bygger jeg også professionelle tracking setups med f.eks. Google Analytics og Google Tag Manager.</p>
                    <p>
                        Du kan læse mine <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">anmeldelser</a> eller lære mere om min karriere på{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            LinkedIn.
                        </a>{" "}
                        Min personlige historie og andre detaljer findes på min <Link to="/da/om-mig">om mig side</Link>.
                    </p>
                    <br />
                    <br />
                    <H as="h6" style={{ "textAlign": "center" }}>Hvem jeg har arbejdet med</H>
                    <ImgScreenshot
                        src="about/clients_700px.png"
                        alt="klienter jeg har arbejdet med"
                        className="article-img"
                    />
                    <br />
                    <br />
                    <br />


                    <H as="h2" style={{ "textAlign": "center" }}>Få et tilbud</H>
                    <ContactForm showHeadline={false} formName="ga consultant (DA) - kontaktformular" />

                    <H as="h2">SEO Services</H>
                    <p>
                        Generelt arbejder jeg på alle vigtige SEO ranking faktorer for at optimere hjemmesider til søgemaskiner, ligesom
                        ethvert SEO firma.
                    </p>
                    <p>Her er en kort liste over mine SEO services:</p>
                    <ul>
                        <li>On-page & Off-page optimering</li>
                        <li>Skalerbare backlink strategier</li>
                        <li>Indholdsskabelse på flere sprog</li>
                        <li>JavaScript SEO</li>
                        <li>Load speed optimering</li>
                        <li>Teknisk SEO rådgivning</li>
                        <li>Site struktur</li>
                        <li>Accelerated Mobile Pages (AMP)</li>
                        <li>ingen lokal SEO</li>
                    </ul>
                    <p>Selvom jeg fokuserer på SEO, er jeg godt klar over, at der er andre kraftfulde betalte trafik kilder udover søgetrafik, f.eks. Facebook, Twitter eller Google ads. Derfor implementerer jeg mine SEO-teknikker med andre internet marketing discipliner i tankerne, så vi kan <b>udnytte organisk trafik til retargeting</b> i betalte søgekampagner og sociale mediekampagner.</p>
                    <p>På grund af min viden om webudvikling, kan jeg også hjælpe dig med webdesign opgaver i HTML, CSS og JavaScript eller konfigurationer i Google Tag Manager og Google Analytics.</p>

                    <H as="h2">Timeløn og omkostninger</H>
                    <p>Min rate for freelance SEO rådgivning er <b>{hourlyRate} €</b> netto per time. De samlede omkostninger for et SEO-projekt afhænger af dine egne mål, men også af din nuværende site optimering og konkurrenternes indsats.</p>
                    <p>Nedenfor er nogle faktorer, der påvirker prisen på mit SEO rådgivningsarbejde:</p>
                    <ul>
                        <li>Samlet antal sider på domænet</li>
                        <li>Alder og autoritet af domænet</li>
                        <li>Konkurrenceevne i niche</li>
                        <li>Tekniske udfordringer</li>
                        <li>Tilpasningsevne af frontend</li>
                        <li>Antal sprog vi optimerer for</li>
                        <li>Hvor meget nyt webindhold har vi brug for?</li>
                        <li>Hvilken linkbuilding strategi er påkrævet?</li>
                        <li>Er der andre partnere, der dækker opgaver for indhold eller links?</li>
                    </ul>
                    <br />
                    <p>Minimum timeløn er som regel <b>mindst 15 arbejdstimer per måned</b>.</p>
                    <p>Potentielle klienter skal stole på SEO ekspertens vurdering af, hvor stærk konkurrencen er, hvad der kræves af site optimering, og hvilke muligheder der findes.</p>
                    <p>Læs videre nedenfor, hvor jeg forklarer <Link to="/da/seo-freelancer">hvordan man hyrer den rette seo freelancer</Link>.</p>
                    <p>For at fastsætte prisen defineres kontraktens omfang ved projektstart, baseret på ovenstående faktorer.</p>
                    <p>For <b>løbende SEO optimering</b> er et månedligt projektomfang baseret på opgaver og milepæle. Baseret på timeløn, planlagte SEO-indsatser og påkrævet arbejdstid, er SEO omkostninger planlagte og begrænsede for hver måned.</p>
                    <p>Efter en aftale kan jeg begynde mit arbejde, så snart jeg får adgang til hjemmesiden og relaterede værktøjer.</p>
                    <p>Jeg leverer <b>SEO rapporter</b> i form af et live dashboard, så du har fuld gennemsigtighed om keyword rankings og den overordnede succes af min SEO service. Jeg betaler selv for en række SEO værktøjer og inkluderer dem i prisen.</p>
                    <p>SEO klienter kan nogle gange kun have brug for et <b>mindre engangsprojekt</b>. For eksempel en <b>load speed optimering</b>, en <b>teknisk SEO audit, konkurrentanalyse</b> eller implementering af vigtige landing pages som <b>AMP pages</b>.</p>
                    <p>Sådanne mindre projekter afgrænses individuelt og er selvfølgelig uafhængige af ovenstående faktorer.</p>


                    <H as="h2">Fordele ved en SEO freelancer vs. et bureau</H>
                    <p>Som en generel regel, er freelancere en god pasform for klienter, der <b>ved hvad de vil have</b>.</p>
                    <p>Normalt er det en virksomhed, der har arbejdet med et bureau før og har en kyndig person in-house. De har mindre behov for håndholdning og ser snarere efter pålidelig udførelse. De vil have arbejdet gjort med en høj grad af sikkerhed uden for mange powerpoints og upsell-taktikker.</p>
                    <p>Freelancere leverer netop det. De fokuserer på udførelse og har begrænset tid til kommunikation.</p>
                    <p>Opsummeret er fordelene ved at arbejde med en freelancer som følger:</p>
                    <ul>
                        <li><b>bedre pris</b></li>
                        <li><b>fleksibilitet</b> fordi du kan hyre efter behov</li>
                        <li>flere års <b>erfaring</b></li>
                        <li>mere <b>specialiserede</b> profiler tilgængelige</li>
                        <li><b>kommunikation</b> direkte med specialisten</li>
                    </ul>

                    <H as="h2">Ulemper</H>
                    <p>Ulemper ved at arbejde med en freelancer er normalt relateret til, men ikke begrænset til, skalerbarhed. I sidste ende arbejder du med én person, der kun har to hænder. Med et team får du mere arbejde gjort på kortere tid.</p>
                    <p>Andre potentielle ulemper er:</p>
                    <ul>
                        <li><b>Skaleringsbegrænsninger:</b> Hvis du forventer, at antallet af opgaver vil stige, kan én person ikke være nok til jobbet.</li>
                        <li><b>Begrænsninger på grund af ekspertise:</b> Et bureau vil have et bredere udvalg af folk til rådighed til rådgivning.</li>
                        <li><b>Pålidelighed:</b> Sygdom eller uventede livsforstyrrelser kan bedre kompenseres med et team til rådighed.</li>
                        <li><b>Personlighedsmismatch:</b> Hvis du ikke klikker med din freelancer, kan du være nødt til at gå videre og spilde tid. Et bureau vil kunne sætte dig i kontakt med en anden person.</li>
                    </ul>


                    <H as="h2">Hvordan hyrer man den rigtige freelancer?</H>
                    <p>Ved ansættelse af SEO-freelancere er det vigtigt at validere erfaringen og SEO-færdighederne for hver profil.</p>
                    <p>For eksempel vil du sikre, at din hjemmesides teknologi matcher freelancerens tidligere projekter. Hvis du driver en WordPress-hjemmeside, vil de fleste freelancere være komfortable med at arbejde med den. Men hvis du driver en meget tilpasset webstack med f.eks. React eller Angular i frontend og Sitecore eller Umbraco i backend, vil du sikre, at det ikke udgør nogen udfordringer.</p>
                    <p>Individuelle SEO-specialister er muligvis ikke vant til at implementere ændringer via Git, men din hjemmeside kræver det. Så tal gennem processen med at implementere ændringer på hjemmesiden for at afdække potentielle flaskehalse.</p>
                    <p>Nogle virksomheder bygger deres web-tilstedeværelse selv med en intern udvikler, mens andre arbejder med et webbureau. Dette har implikationer for SEO-rådgivning, da ændringer enten kan implementeres direkte eller skal gå gennem et eksternt bureau. Dette faktum alene påvirker omkostningerne og den nødvendige tid for en SEO-kampagne, fordi flere kommunikationslag er nødvendige, indtil en ændring er implementeret.</p>
                    <p>Så uafhængige SEO-eksperter skal være komfortable med det givne setup af interne og eksterne teams og overveje dets indvirkning på den overordnede SEO-strategi.</p>
                    <p>Også track record og hvor freelanceren har arbejdet før spiller en rolle for at drage konklusioner om arbejdsetik og pålidelighed. Indgangsbarrieren for seo-freelancere fra Upwork er ret lav og kan derfor ikke levere den rigtige kandidat (ikke at det ikke er muligt). Derfor håber jeg at tilbyde noget gennemsigtighed ved at dele min{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            LinkedIn profil
                        </a>{" "}
                        på forhånd.
                    </p>
                    <p>Når du finder en person, bør en simpel e-mail eller udfyldt kontaktformular være nok til at indlede en samtale. En professionel SEO-freelancer tager dog ikke hvert projekt, der lander på bordet. Og det er godt for kunderne, så tag ikke en afvisning personligt.</p>
                    <p>Der er varierende SEO-strategier for forskellige nicher og keyword intents. Freelancere vil sikre, at nichekravene og webteknologien matcher deres ekspertise.</p>
                    <p>Hvis de ikke gør det, bør kunder være skeptiske. Medmindre du har brug for en nogenlunde standard SEO-opgave, ønsker du at finde en SEO-freelancer, der er en ekspert for din sag.</p>
                    <p>Efter projektscope og omkostninger er aftalt skriftligt, skal freelanceren have adgang til hjemmesiden. Det betyder adgang til frontend, CMS og analytics og potentielt en FTP-server. Så snart adgang er givet, er SEO-eksperten ansat og arbejdstiden starter officielt.</p>

                    <H as="h2">Hvordan blev jeg en freelancer for søgemaskineoptimering?</H>
                    <p>Jeg byggede min første private hjemmeside som 14-årig, så jeg kunne dele min filmsamling med skolevenner. I vores landsby var jeg også barnet, der reparerede computerne og lavede mange geninstallationer af operativsystemer.</p>
                    <p>
                        Da jeg var 19, byggede jeg Kickerkult.de, en e-handelsbutik for bordfodbold og begyndte at lave søgemaskine
                        optimering for min egen keyword research. Dengang var det næsten nok at optimere meta keywords og title tags.
                    </p>
                    <p>At nørde med hjemmesider og teknologi som helhed har altid været sjovt for mig, så jeg må have valgt naturligt at arbejde fuldtid i webbureauer og online marketing senere. Kollegaerne og projekterne i disse bureauer havde en god indflydelse på at udvide mine færdigheder og gøre mine arbejdsgange og processer mere professionelle.</p>
                    <p>Efter jeg havde haft min del af SEO-job, blev fremskridt sammen med et digitalt marketingbureau stadig sværere, så freelance SEO-rådgivning blev det logiske næste karriereskridt for mig.</p>
                    <p>De fleste <a href="https://www.statista.com/statistics/946967/freelancers-in-europe-by-sector/" target="_blank">freelancere i Europa (39%) arbejder i marketing- og kommunikationssektoren</a>. Jeg ønskede at gå dybere ind i mit emne og kontrollere hvilke projekter jeg arbejder på, så freelancing blev det næste skridt for at sikre, at hver time bruges på en meningsfuld opgave.</p>
                    <p>Det viser sig, at jeg ikke er helt alene med den tanke. Faktisk er <a href="https://apo.org.au/sites/default/files/resource-files/2016-05/apo-nid201721.pdf" target="_blank">freelancere eller iPros den hurtigst voksende gruppe på EU-arbejdsmarkedet siden 2004</a> og <a href="https://news.malt.com/wp-content/uploads/2019/02/FREELANCING-IN-EUROPE-2-1.pdf" target="_blank">de udgør omkring 7% af den samlede arbejdsstyrke i EU</a>.</p>
                    <p>Jeg kan bruge mere tid nu på indhold, keyword research og linkbuilding, fordi jeg har brug for mindre tid til administrative opgaver eller projektledelse, mens mine kunder får mere faktisk arbejde for deres budget. Freelancing fuld tid giver mig derfor en hurtigere feedback-loop, som er nødvendig for at konkurrere og normalt ikke opnåelig i et SEO-bureau.</p>
                    <p>Jeg har arbejdet som freelance SEO-specialist siden 2018 nu. Jeg arbejder mest med enterprise-klienter, der har multinationale hjemmesider, samt e-handelsbutikker efter en tilgang drevet af content marketing. Jeg laver ikke lokal SEO.</p>

                    <H as="h3">Leder du efter SEO-hjælp?</H>
                    <p>
                        Hvis du leder efter en freelance SEO-ekspert til at hjælpe dig med dine Google-rangeringer, er du velkommen til at kontakte
                        mig. Jeg vender normalt tilbage med en vurdering af situationen og en plan inklusive et tilbud.
                    </p>
                    <p>
                        Du kan ellers finde mere information på min <Link to="/da/om-mig">om side</Link> og måske nogle
                        interessante læsninger på min <Link to="/da/blog">blog</Link>.
                    </p>
                    <H as="h4" style={{ "textAlign": "center" }}>Kontakt mig for et uforpligtende tilbud.</H>

                </MainContent>
            </React.Fragment>

        </Layout>
    );
};

export default SeoFreelancer;